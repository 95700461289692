<script setup>
import LanguageConverter from '@/util/LanguageConverter'

const loginLinks = [
  { link: '/member/login', name: '로그인', nameEn: 'Log in' },
  { link: '/member/register', name: '회원가입', nameEn: 'Sign up' },
  { link: '/member/findId', name: '아이디찾기', nameEn: 'Find ID' },
  { link: '/member/findPw', name: '비밀번호찾기', nameEn: 'Find Password' }
]
</script>

<template>
  <article class="wf__login-links">
    <ul>
      <li v-for="(item, i) in loginLinks" :key="i">
        <router-link :to="item.link" class="wf__login-link-item">{{ LanguageConverter({ en: item.nameEn, kr: item.name }) }}</router-link>
      </li>
    </ul>
  </article>
</template>

<style scoped>
.wf__login-links {
  text-align: left;
  font-size: var(--wf-text-16);
}
.wf__login-links li {
  padding: 0.5em 0;
}
.wf__login-link-item {
  position: relative;
  padding-left: 1.5em;
  margin: 0.5em 0;
}
.router-link-exact-active::after {
  position: absolute;
  top: 0.25em;
  left: 0;
  display: inline-block;
  content: '';
  width: 0.75em;
  height: 0.75em;
  background-color: var(--wf-primary-color);
  border-radius: 9999px;
}

@media screen and (max-width: 992px) {
  .wf__login-links {
    display: none;
  }
}
</style>
